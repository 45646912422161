
















import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {RegisterCompanyCustomerRequestDTO} from "@/dto/request/rccr/RegisterCompanyCustomerRequestDTO";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import RCCRService from "@/services/request/RCCRService";

const AppModule = namespace("App");

@Component({
  components: {SingleFileHolder}
})
export default class RCCR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private newFile: FileMetaDTO | null = null;

  request: RegisterCompanyCustomerRequestDTO | null = null;

  mounted() {
    this.loadRequest();
  }

  loadRequest() {
    this.startLoading();
    RCCRService.getPublicRCCR(this.id).then(
        response => {
          this.request = response.data;
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    );
  }

  get id() {
    return this.$route.params.id;
  }

  showUploadNewDocumentModal() {
    this.$modal.show(
        FileUpload,
        {
          multiple: true,
          onUploaded: (files: FileMetaDTO[]) => this.onNewFilesUploaded(files)
        }
    )
  }

  onNewFilesUploaded(files: FileMetaDTO[]) {
    this.startLoading();
    RCCRService.uploadRCCRDocument(this.id, files[0].id).then(
        response => {
          this.$modal.hideAll();
          this.loadRequest();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  onNewFileUploaded(files: FileMetaDTO) {
    this.startLoading();
    RCCRService.uploadRCCRDocument(this.id, files.id).then(
        response => {
          this.$modal.hideAll();
          this.newFile = null;
          this.loadRequest();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }


}
